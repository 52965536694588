//links.js
import React from 'react'
import { Link } from 'react-router-dom'

const Links = ({ links }) => {
	return (
		<React.Fragment>
			{links.map((link, idx) => (
				<Link
					key={`${link.title}-${idx}`}
					to={link.link}
					className={
						'mx-4 text-center rounded-theme-rounding bg-ssiGold1 dark:bg-ssiGold1 py-2 px-4 text-ssiDark2 font-semibold dark:text-ssiDark4 hover:opacity-80 hover:-translate-y-0.5'
					}
				>
					{link.title}
				</Link>
			))}
		</React.Fragment>
	)
}

export default Links
