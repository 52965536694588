import React, { useEffect } from 'react'
import { PageWrapper, Links } from '../../components'

import { ROUTES_PAGES } from '../../constants'
import { useDispatch, useSelector } from 'react-redux'
import { listProjects } from '../../redux/actions/projectActions'
import { Link } from 'react-router-dom'

const links = [
	{ title: 'HOME', link: ROUTES_PAGES.projects },
	{ title: 'ADD NEW', link: `${ROUTES_PAGES.projects}${ROUTES_PAGES.add}` },
	{
		title: 'VIEW ALL',
		link: `${ROUTES_PAGES.projects}${ROUTES_PAGES.viewAll}`,
	},
]

const Projects = () => {
	const dispatch = useDispatch()

	const listProjectsRdx = useSelector((state) => state.listProjects)
	const { data, loading, error } = listProjectsRdx

	useEffect(() => {
		dispatch(listProjects())

		return () => {}
	}, [])

	useEffect(() => {
		console.log(listProjectsRdx)

		return () => {}
	}, [listProjectsRdx])
	return (
		<PageWrapper
			sidebarTitle='PROJECT LINKS'
			sidebarLinks={<Links links={links} />}
		>
			<div className='mt-4 mb-8'>
				<div className='font-montserrat font-semibold text-xl  text-center pb-4'>
					PROJECTS SUMMARY
				</div>
				<div className='font-montserrat font-semibold text-xl  text-center pb-4'>
					{data ? (
						<div className='flex  space-x-4 justify-center items-center'>
							<div>{`${data.data.length} Projects -- yada yada`}</div>
							<Link
								className='rounded-theme-rounding py-1 px-4 border-1 border-gray-600'
								to={`${ROUTES_PAGES.projects}${ROUTES_PAGES.viewAll}`}
							>
								See all
							</Link>
						</div>
					) : error ? (
						error
					) : loading ? (
						'Loading project summary...'
					) : (
						'No projects, one now!'
					)}
				</div>
			</div>
		</PageWrapper>
	)
}

export default Projects
