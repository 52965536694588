export const iotConstants = {
	controlOutput: {
		request: 'CONTROL_OUTPUT_REQUEST',
		success: 'CONTROL_OUTPUT_SUCCESS',
		fail: 'CONTROL_OUTPUT_FAIL',
		reset: 'CONTROL_OUTPUT_RESET',
	},
	getDevice: {
		request: 'GET_DEVICE_REQUEST',
		success: 'GET_DEVICE_SUCCESS',
		fail: 'GET_DEVICE_FAIL',
	},
}
