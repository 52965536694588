import React from 'react'
import {
	AiOutlineCalendar,
	AiOutlineFundProjectionScreen,
	AiOutlineAreaChart,
	AiOutlineBarChart,
	AiOutlineStock,
	AiOutlinePlus,
	AiOutlineMenu,
	AiOutlineMinus,
	AiOutlineEdit,
	AiOutlineNotification,
	AiOutlineLogout,
} from 'react-icons/ai'
import {
	BiColorFill,
	BiMessageRoundedError,
	BiMove,
	BiHappyHeartEyes,
	BiHappyBeaming,
	BiHappy,
	BiSad,
} from 'react-icons/bi'
import {
	BsKanban,
	BsBarChart,
	BsCurrencyDollar,
	BsPeople,
	BsChatLeft,
	BsHouseDoor,
	BsBuilding,
	BsCheck,
	BsShield,
	BsCalendar4Range,
	BsBoxSeam,
	BsChevronDown,
	BsHandThumbsUp,
	BsHandThumbsDown,
} from 'react-icons/bs'
import {
	FaTasks,
	FaDrawPolygon,
	FaRegSadCry,
	FaRegSadTear,
	FaProjectDiagram,
	FaIndustry,
} from 'react-icons/fa'
import { FaChartGantt } from 'react-icons/fa6'

import {
	FiEdit,
	FiPieChart,
	FiShoppingCart,
	FiBookOpen,
	FiSettings,
	FiFilter,
	FiBarChart,
	FiCreditCard,
	FiStar,
} from 'react-icons/fi'
import { GiLouvrePyramid } from 'react-icons/gi'
import { GoDot } from 'react-icons/go'
import { GrLocation, GrCheckboxSelected, GrCheckbox } from 'react-icons/gr'
import { AiOutlineScan } from 'react-icons/ai'

import {
	HiOutlineDocumentReport,
	HiClipboardList,
	HiOutlineRefresh,
} from 'react-icons/hi'
import { ImFolderOpen } from 'react-icons/im'
import { IoMdFunnel, IoIosMore, IoIosArrowBack } from 'react-icons/io'
// import { LiaIndustrySolid } from 'react-icons/lia'
import {
	MdOutlineAddCircleOutline,
	MdOutlineCancel,
	MdKeyboardArrowDown,
	MdOutlineSupervisorAccount,
	MdOutlineToday,
	MdDragIndicator,
	MdDarkMode,
	MdOutlineWbSunny,
	MdDoNotDisturbAlt,
} from 'react-icons/md'

import {
	RiDeleteBinLine,
	RiContactsLine,
	RiStockLine,
	RiCustomerService2Line,
	RiOrganizationChart,
	RiDashboard3Line,
	RiHammerLine,
	RiAdminLine,
	RiNotification3Line,
	RiMicroscopeLine,
	RiScales2Line,
	RiMindMap,
} from 'react-icons/ri'
import { SiShopware } from 'react-icons/si'

import {
	TbDashboard,
	TbReport,
	TbChevronRight,
	TbChevronLeft,
} from 'react-icons/tb'
import { TiTick } from 'react-icons/ti'

import { VscTable, VscFeedback } from 'react-icons/vsc'

export const ICONS_MONEY = {
	creditCard: <FiCreditCard />,
	currency: <BsCurrencyDollar />,
	check: <BsCheck />,
	shield: <BsShield />,
}

export const ICONS_OV = {
	products: {
		weighMate: <RiScales2Line />,
		seamMate: <RiMicroscopeLine />,
		scanPro: <AiOutlineScan />, //GrScan//
	},
	reports: {
		shiftReport: <TbReport />,
	},
}
export const ICONS_DATA = {
	today: <MdOutlineToday />,
	table: <VscTable />,
	folder: <ImFolderOpen />,
	clipboard: <HiClipboardList />,
	filter: <FiFilter />,
	bookOpen: <FiBookOpen />,
	calendar: <BsCalendar4Range />,
	mindmap: <RiMindMap />,
}
export const ICONS_DASHBOARDS = {
	production: <FaChartGantt />,
	designViews: <FaDrawPolygon />,
	humanResourceDashboard: <RiOrganizationChart />,
	marketingDashboard: <IoMdFunnel />,
	salesDashboard: <BsCurrencyDollar />,
	operationsDashboard: <RiHammerLine />,
	customerCareDashboard: <RiCustomerService2Line />,
	executiveDashboard: <TbDashboard />,
	companyDashboard: <RiDashboard3Line />,
	projectsDashboard: <FaProjectDiagram />,
	assetsDashboard: <FaIndustry />,
}
export const ICONS_DASHBOARDS_FN = {
	designViews: (props) => <FaDrawPolygon {...props} />,
	humanResourceDashboard: (props) => <RiOrganizationChart {...props} />,
	marketingDashboard: (props) => <IoMdFunnel {...props} />,
	salesDashboard: (props) => <BsCurrencyDollar {...props} />,
	operationsDashboard: (props) => <RiHammerLine {...props} />,
	customerCareDashboard: (props) => <RiCustomerService2Line {...props} />,
	executiveDashboard: (props) => <TbDashboard {...props} />,
	companyDashboard: (props) => <RiDashboard3Line {...props} />,
}

export const ICONS_CHARTS = {
	line: <AiOutlineStock />,
	area: <AiOutlineAreaChart />,
	bar1: <AiOutlineBarChart />,
	pie: <FiPieChart />,
	financial: <RiStockLine />,
	colorMapping: <BsBarChart />,
	pyramid: <GiLouvrePyramid />,
	stacked: <AiOutlineBarChart />,
	bar2: <FiBarChart />,
}

export const ICONS_PAGES = {
	projectsPage: <AiOutlineFundProjectionScreen />,
	employeesPage: <BsPeople />,
	tasksPage: <FaTasks />,
	customersPage: <RiContactsLine />,
}

export const ICONS_APPS = {
	calendarApp: <AiOutlineCalendar />,
	kanbanApp: <BsKanban />,
	editorApp: <FiEdit />,
	colorPickerApp: <BiColorFill />,
}

export const ICONS_CONSTRUCTION = {
	building: <BsBuilding />,
	house: <BsHouseDoor />,
}

export const ICONS_EMOTICONS = {
	thumbsUp: <BsHandThumbsUp />,
	thumbsDown: <BsHandThumbsDown />,
	happyHeart: <BiHappyHeartEyes />,
	happyBeaming: <BiHappyBeaming />,
	happy: <BiHappy />,
	// laughWink: <FaRegFaceLaughWink />,
	sad: <BiSad />,
	tear: <FaRegSadTear />,
	cry: <FaRegSadCry />,
}

export const ICONS_DEFAULT = {
	doNotDisturb: <MdDoNotDisturbAlt />,
	chevronRight: <TbChevronRight />,
	chevronLeft: <TbChevronLeft />,
	error: <BiMessageRoundedError />,
	edit: <AiOutlineEdit />,
	move: <BiMove />,
	feedback: <VscFeedback />,
	adminSettings: <RiAdminLine />,
	settings: <FiSettings />,
	cart: <FiShoppingCart />,
	dot: <GoDot />,
	chat: <BsChatLeft />,
	notification: <RiNotification3Line />,
	announcement: <AiOutlineNotification />,
	reports: <HiOutlineDocumentReport />,
	cancel: <MdOutlineCancel />,
	minus: <AiOutlineMinus />,
	plus: <AiOutlinePlus />,
	addCircle: <MdOutlineAddCircleOutline />,
	menu: <AiOutlineMenu />,
	deleteTrash: <RiDeleteBinLine />,
	downArrow: <MdKeyboardArrowDown />,
	downChevron: <BsChevronDown />,
	shopWare: <SiShopware />,
	moreDots: <IoIosMore />,
	dragDots: <MdDragIndicator />,
	currency: <BsCurrencyDollar />,
	filter: <FiFilter />,
	star: <FiStar />,
	checkMark: <TiTick />,
	checkBox: <GrCheckbox />,
	checkBoxChosen: <GrCheckboxSelected />,
	location: <GrLocation />,
	refresh: <HiOutlineRefresh />,
	profileSupevisor: <MdOutlineSupervisorAccount />,
	boxSeam: <BsBoxSeam />,
	back: <IoIosArrowBack />,
	lightMode: <MdOutlineWbSunny />,
	darkMode: <MdDarkMode />,
	logout: <AiOutlineLogout />,
}

export const ICONS = {
	onevision: ICONS_OV,
	apps: ICONS_APPS,
	charts: ICONS_CHARTS,
	pages: ICONS_PAGES,
	dashboards: ICONS_DASHBOARDS,
	default: ICONS_DEFAULT,
	data: ICONS_DATA,
}
