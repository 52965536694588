import React, { useEffect } from 'react'
import { PageWrapper, Links } from '../../components'

import { ROUTES_PAGES } from '../../constants'
import { useDispatch, useSelector } from 'react-redux'
import { listProjects } from '../../redux/actions/projectActions'
import ProjectListItem from '../../components/projects/project-list-item'

const links = [
	{ title: 'HOME', link: ROUTES_PAGES.projects },
	{ title: 'ADD NEW', link: `${ROUTES_PAGES.projects}${ROUTES_PAGES.add}` },
	{
		title: 'VIEW ALL',
		link: `${ROUTES_PAGES.projects}${ROUTES_PAGES.viewAll}`,
	},
]

const ListProjects = () => {
	const dispatch = useDispatch()

	const listProjectsRdx = useSelector((state) => state.listProjects)
	const { data, loading, error } = listProjectsRdx

	useEffect(() => {
		dispatch(listProjects())

		return () => {}
	}, [])

	useEffect(() => {
		console.log(listProjectsRdx)

		return () => {}
	}, [listProjectsRdx])

	return (
		<PageWrapper
			sidebarTitle='PROJECT LINKS'
			sidebarLinks={<Links links={links} />}
		>
			<div className='mt-4 mb-8'>
				<div className='font-montserrat font-semibold text-xl  text-center pb-4'>
					PROJECTS LIST
				</div>
				{error && (
					<div className='font-montserrat font-semibold text-xl  text-center pb-4'>
						{error}
					</div>
				)}
				<div className='flex flex-col space-y-4'>
					{data?.data &&
						data.data.map((item) => (
							<ProjectListItem key={`${item._id}`} project={item} />
						))}
				</div>
			</div>
		</PageWrapper>
	)
}

export default ListProjects
