import React, { useEffect } from 'react'
import { PageWrapper, Links } from '../../components'

import { ROUTES_PAGES } from '../../constants'
import { useDispatch, useSelector } from 'react-redux'
import { listAssets } from '../../redux/actions/assetActions'
import AssetListItem from '../../components/assets/asset-list-item'

const links = [
	{ title: 'HOME', link: ROUTES_PAGES.assets },
	{ title: 'ADD NEW', link: `${ROUTES_PAGES.assets}${ROUTES_PAGES.add}` },
	{
		title: 'VIEW ALL',
		link: `${ROUTES_PAGES.assets}${ROUTES_PAGES.viewAll}`,
	},
]

const ListAssets = () => {
	const dispatch = useDispatch()

	const listAssetsRdx = useSelector((state) => state.listAssets)
	const { data, loading, error } = listAssetsRdx

	useEffect(() => {
		console.log('enter')
		dispatch(listAssets())

		return () => {}
	}, [])

	useEffect(() => {
		console.log(listAssetsRdx)

		return () => {}
	}, [listAssetsRdx])

	return (
		<PageWrapper
			sidebarTitle='PROJECT LINKS'
			sidebarLinks={<Links links={links} />}
		>
			<div className='mt-4 mb-8'>
				<div className='font-montserrat font-semibold text-xl  text-center pb-4'>
					ASSETS LIST
				</div>
				{error && (
					<div className='font-montserrat font-semibold text-xl  text-center pb-4'>
						{error}
					</div>
				)}
				<div className='flex flex-col space-y-4'>
					{data?.data &&
						data.data.map((item) => (
							<AssetListItem key={`${item._id}`} asset={item} />
						))}
				</div>
			</div>
		</PageWrapper>
	)
}

export default ListAssets
