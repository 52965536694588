import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTES_PAGES } from '../../constants'

const ProjectListItem = ({ project }) => {
	return (
		<Link
			to={`${ROUTES_PAGES.project}?id=${project._id}`}
			className='flex justify-between p-6 border-1 border-gray-600 rounded-theme-rounding'
		>
			<div className='flex flex-col'>
				<div>{project.name}</div>
				<div>{project.description}</div>
			</div>
			<div className='flex flex-col '>
				<img
					src={project.barcode}
					alt='Project QR Code'
					style={{ maxWidth: '100px', borderRadius: '15px', borderWidth: 2 }}
				/>
			</div>
		</Link>
	)
}

export default ProjectListItem
