import React from 'react'

const Footer = () => (
	<div className='mt-80'>
		<p className='text-sm text-ssiLightTextInactive dark:text-ssiDarkTextInactive text-center m-20'>
			© 2025 All rights reserved | Powered by Zeidos.com
		</p>
	</div>
)

export default Footer
