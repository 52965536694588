import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTES_PAGES } from '../../constants'

const AssetListItem = ({ asset }) => {
	return (
		<Link
			to={`${ROUTES_PAGES.asset}?id=${asset._id}`}
			className='flex justify-between p-6 border-1 border-gray-600 rounded-theme-rounding'
		>
			<div className='flex flex-col'>
				<div>{asset.name}</div>
				<div>{asset.description}</div>
			</div>
			<div className='flex flex-col '>
				<img
					src={asset.barcode}
					alt='Asset QR Code'
					style={{ maxWidth: '100px', borderRadius: '15px', borderWidth: 2 }}
				/>
			</div>
		</Link>
	)
}

export default AssetListItem
