import axios from 'axios'
import { REDUX_CONST } from '../../constants'
import { API_CONST } from '../../../constants/api'

export const getDevice =
	({ device }) =>
	async (dispatch, getState) => {
		try {
			dispatch({
				type: REDUX_CONST.iotConstants.getDevice.request,
			})
			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
					'Content-Type': 'application/json',
				},
			}

			const { data } = await axios.get(
				`${API_CONST.channels.iot}${API_CONST.routes.getDevice}/${device}`,
				config,
			)

			console.log(data)

			dispatch({
				type: REDUX_CONST.iotConstants.getDevice.success,
				payload: data,
			})
		} catch (error) {
			console.log(error)
			dispatch({
				type: REDUX_CONST.iotConstants.getDevice.fail,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const controlOutput =
	(action, device, pin) => async (dispatch, getState) => {
		try {
			dispatch({
				type: REDUX_CONST.iotConstants.controlOutput.request,
			})
			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
					'Content-Type': 'application/json',
				},
			}

			const { data } = await axios.get(
				`${API_CONST.channels.iot}${API_CONST.routes.controlOutput}/${action}?device=${device}&pin=${pin}`,
				config,
			)

			console.log(data)

			dispatch({
				type: REDUX_CONST.iotConstants.controlOutput.success,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: REDUX_CONST.iotConstants.controlOutput.fail,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}
