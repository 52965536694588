const { userConstants } = require('./userConstants/userConstants')
const { autodeskConstants } = require('./autodeskConstants')
const { projectConstants } = require('./projectConstants')
const { assetConstants } = require('./assetConstants')
const { iotConstants } = require('./iotConstants')

exports.REDUX_CONST = {
	userConstants,
	autodeskConstants,
	iotConstants,
	projectConstants,
	assetConstants,
}
