export const projectConstants = {
	listProjects: {
		request: 'PROJECTS_LIST_REQUEST',
		success: 'PROJECTS_LIST_SUCCESS',
		fail: 'PROJECTS_LIST_FAIL',
		reset: 'PROJECTS_LIST_RESET',
	},
	fetchProject: {
		request: 'PROJECT_FETCH_REQUEST',
		success: 'PROJECT_FETCH_SUCCESS',
		fail: 'PROJECT_FETCH_FAIL',
	},
	uploadProjectFile: {
		request: 'UPLOAD_PROJECT_FILE_REQUEST',
		success: 'UPLOAD_PROJECT_FILE_SUCCESS',
		fail: 'UPLOAD_PROJECT_FILE_FAIL',
		reset: 'UPLOAD_PROJECT_FILE_RESET',
	},
	createProject: {
		request: 'CREATE_PROJECT_REQUEST',
		success: 'CREATE_PROJECT_SUCCESS',
		fail: 'CREATE_PROJECT_FAIL',
		reset: 'CREATE_PROJECT_RESET',
	},
	// deleteHubDrawing: {
	// 	request: 'DELETE_DRAWING_FROM_HUB_REQUEST',
	// 	success: 'DELETE_DRAWING_FROM_HUB_SUCCESS',
	// 	fail: 'DELETE_DRAWING_FROM_HUB_FAIL',
	// 	reset: 'DELETE_DRAWING_FROM_HUB_RESET',
	// },
}
