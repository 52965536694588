// CONSTANTS
export const CONST_DARK = 'Dark'
export const CONST_LIGHT = 'Light'
export const CONST_START = 'Start'
export const CONST_STOP = 'Stop'
export const CONST_SUCCESS = 'Success'
export const CONST_FAIL = 'Fail'
export const CONST_ERROR = 'Error'
export const CONST_PREV = 'Previous'
export const CONST_NEXT = 'Next'
export const CONST_BUTTON = 'label'
export const CONST_LABEL = 'button'
export const CONST_EDIT = 'edit'
export const CONST_ADD = 'add'
export const CONST_DELETE = 'delete'

export const EXAMPLE_MACHINES = [
	{
		machineId: 11,
		machineName: 'HCN - A',
	},
	{
		machineId: 21,
		machineName: 'HCN - B',
	},
	{
		machineId: 51,
		machineName: 'HCN - C',
	},
	{
		machineId: 41,
		machineName: 'HCN - D',
	},
]

export const GEN_CONST = {
	on: 'ON',
	off: 'OFF',
	HIGH: 'HIGH',
	LOW: 'LOW',
	dark: CONST_DARK,
	light: CONST_LIGHT,
	start: CONST_START,
	stop: CONST_STOP,
	success: CONST_SUCCESS,
	fail: CONST_FAIL,
	error: CONST_ERROR,
	previous: CONST_PREV,
	next: CONST_NEXT,
	label: CONST_LABEL,
	button: CONST_BUTTON,
	edit: CONST_EDIT,
	add: CONST_ADD,
	delete: CONST_DELETE,
	urlParameters: {
		FNAME: 'fn',
		EMAIL: 'em',
		PHONE: 'ph',
	},
}

export const CONST_DATES = {
	all: 'allDates',
	today: 'today',
	thisWeek: 'thisWeek',
	thisMonth: 'thisMonth',
	last7Days: 'last7Days',
	last30Days: 'last30Days',
	last90Days: 'last90Days',
	last180Days: 'last180Days',
	thisQuarter: 'thisQuarter',
	thisYear: 'thisYear',
	thisYearToDate: 'thisYearToDate',
	lastYear: 'lastYear',
	lastYearToDate: 'lastYearToDate',
	day: 'Day',
	week: 'Week',
	month: 'Month',
}

export const CONST_MONGO_DB_FIELDS = {
	createdAt: { alias: 'createdAt', name: 'Date' },
	jobID: { alias: 'jobID', name: 'Job #' },
	eventContact: { alias: 'eventContact', name: 'Created By' },
	eventType: { alias: 'eventType', name: 'Type' },
	leadSource: { alias: 'leadSource', name: 'Lead Source' },
	leadType: { alias: 'leadType', name: 'Lead Type' },
	jobType: { alias: 'jobType', name: 'Job Type' },
	phase: { alias: 'phase', name: 'Job Phase' },
	template: { alias: 'template', name: 'Template Used' },
}

export const CONST_COLORS = {
	// BE SURE TO UPDATE tailwind-theme-properties.js
	ssiGold1: 'hsl(44deg 97% 53%)',
	ssiLight1: 'hsl(0deg 0% 100%)',
	ssiLight2: 'hsl(360deg 0% 97%)',
	ssiLight3: 'hsl(360deg 0% 92%)',
	ssiLight4: 'hsl(360deg 0% 88%)',
	ssiLightBorder1: 'hsl(360deg 0% 90%)',
	ssiLightTextInactive: 'hsl(0deg 0% 75%)',
	ssiLightTextActive: 'hsl(0deg 0% 12%)',
	ssiDark1: 'hsl(0deg 0% 9%)',
	ssiDark2: 'hsl(0deg 0% 12%)',
	ssiDark3: 'hsl(0deg 0% 16%)',
	ssiDark4: 'hsl(0deg 0% 19%)',
	ssiDarkBorder1: 'hsl(0deg 0% 28%)',
	ssiDarkTextInactive: 'hsl(0deg 0% 49%)',
	ssiDarkTextActive: 'hsl(0deg 0% 84%)',
	ssiGray200Scorecard: 'hsl(0deg 0% 96%)',
	ssiGray800Scorecard: 'hsl(0deg 0% 33%)',
	ssiRedScorecard: 'hsl(0deg 99% 34%)',
	ssiGoldScorecard: 'hsl(50deg 91% 46%)',
}
