import { REDUX_CONST } from '../constants'

export const fetchProject = (state = { data: {} }, action) => {
	switch (action.type) {
		case REDUX_CONST.projectConstants.fetchProject.request:
			return { loading: true }
		case REDUX_CONST.projectConstants.fetchProject.success:
			return { data: action.payload }
		case REDUX_CONST.projectConstants.fetchProject.fail:
			return { error: action.payload }
		default:
			return state
	}
}

export const createProject = (state = {}, action) => {
	switch (action.type) {
		case REDUX_CONST.projectConstants.createProject.request:
			return { loading: true }
		case REDUX_CONST.projectConstants.createProject.success:
			return { data: action.payload }
		case REDUX_CONST.projectConstants.createProject.fail:
			return { error: action.payload }
		case REDUX_CONST.projectConstants.createProject.reset:
			return {}
		default:
			return state
	}
}

export const uploadProjectFile = (state = {}, action) => {
	switch (action.type) {
		case REDUX_CONST.projectConstants.uploadProjectFile.request:
			return { loading: true }
		case REDUX_CONST.projectConstants.uploadProjectFile.success:
			return { data: action.payload }
		case REDUX_CONST.projectConstants.uploadProjectFile.fail:
			return { error: action.payload }
		case REDUX_CONST.projectConstants.uploadProjectFile.reset:
			return {}
		default:
			return state
	}
}

export const listProjects = (state = [], action) => {
	switch (action.type) {
		case REDUX_CONST.projectConstants.listProjects.request:
			return { loading: true }
		case REDUX_CONST.projectConstants.listProjects.success:
			return { data: action.payload }
		case REDUX_CONST.projectConstants.listProjects.fail:
			return { error: action.payload }
		case REDUX_CONST.projectConstants.listProjects.reset:
			return []
		default:
			return state
	}
}

// export const deleteUser = (state = {}, action) => {
// 	switch (action.type) {
// 		case REDUX_CONST.userConstants.deleteUser.request:
// 			return { loading: true }
// 		case REDUX_CONST.userConstants.deleteUser.success:
// 			return { data: action.payload }
// 		case REDUX_CONST.userConstants.deleteUser.fail:
// 			return { error: action.payload }
// 		case REDUX_CONST.userConstants.deleteUser.reset:
// 			return {}

// 		default:
// 			return state
// 	}
// }
// export const updateUser = (state = {}, action) => {
// 	switch (action.type) {
// 		case REDUX_CONST.userConstants.updateUser.request:
// 			return { loading: true }
// 		case REDUX_CONST.userConstants.updateUser.success:
// 			return { data: action.payload }
// 		case REDUX_CONST.userConstants.updateUser.fail:
// 			return { error: action.payload }
// 		case REDUX_CONST.userConstants.updateUser.reset:
// 			return {}
// 		default:
// 			return state
// 	}
// }
