import { REDUX_CONST } from '../constants'

export const controlOutput = (state = {}, action) => {
	switch (action.type) {
		case REDUX_CONST.iotConstants.controlOutput.request:
			return { loading: true }
		case REDUX_CONST.iotConstants.controlOutput.success:
			return { data: action.payload }
		case REDUX_CONST.iotConstants.controlOutput.fail:
			return { error: action.payload }
		case REDUX_CONST.iotConstants.controlOutput.reset:
			return {}
		default:
			return state
	}
}
export const getDevice = (state = {}, action) => {
	switch (action.type) {
		case REDUX_CONST.iotConstants.getDevice.request:
			return { loading: true }
		case REDUX_CONST.iotConstants.getDevice.success:
			return { data: action.payload }
		case REDUX_CONST.iotConstants.getDevice.fail:
			return { error: action.payload }
		default:
			return state
	}
}
