// BACKEND API ROUTES (EXPRESS AND ELECTRON)

//MAIN CHANNEL  FOR ELECTRON
export const API_COMMUNICATIONS = 'API_COMMUNICATIONS'
export const MAIN_COMMUNICATIONS = 'MAIN_COMMUNICATIONS'

export const API_CONST = {
	channels: {
		iot: '/api/iot',
		users: '/api/users',
		autodesk: '/api/autodesk',
		projects: '/api/projects',
		assets: '/api/assets',
	},
	routes: {
		controlOutput: '/control-output',
		getDevice: '/get-device',
		listProjects: '/list-projects',
		fetchProject: '/fetch-project',
		createProject: '/create-project',
		listAssets: '/list-assets',
		fetchAsset: '/fetch-asset',
		createAsset: '/create-asset',
		uploadDrawingToHub: '/upload-drawing-to-hub',
		deleteHubDrawing: '/buckets/:bucketKey/objects/:objectName',
		listModels: '/list-models',
		fetchModel: '/fetch-model',
		uploadProjectFile: '/upload-project-file',
		home: '/',
		users: {
			login: '/login1',
			profile: '/profile',
		},
	},
}
