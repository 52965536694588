import axios from 'axios'
import { API_CONST } from '../../constants/api/index.js'
import { REDUX_CONST } from '../constants/index.js'
export const resetItems = (key) => async (dispatch) => {
	switch (key) {
		case 'create':
			dispatch({ type: REDUX_CONST.assetConstants.createAsset.reset })

			break

		default:
			break
	}
}
export const listAssets = () => async (dispatch, getState) => {
	console.log('listAssets')
	try {
		dispatch({
			type: REDUX_CONST.assetConstants.listAssets.request,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(API_CONST.channels.assets, config)

		// console.log(response.data)
		dispatch({
			type: REDUX_CONST.assetConstants.listAssets.success,
			payload: data,
		})
	} catch (error) {
		console.log(error)
		dispatch({
			type: REDUX_CONST.assetConstants.listAssets.fail,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}
export const fetchAsset =
	({ id }) =>
	async (dispatch, getState) => {
		// console.log('file')
		try {
			dispatch({
				type: REDUX_CONST.assetConstants.fetchAsset.request,
			})

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
			}
			const { data } = await axios.get(
				`${API_CONST.channels.assets}/${id}`,
				config,
			)

			dispatch({
				type: REDUX_CONST.assetConstants.fetchAsset.success,
				payload: data,
			})
		} catch (error) {
			console.log(error)
			dispatch({
				type: REDUX_CONST.assetConstants.fetchAsset.fail,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}
export const createAsset =
	({ name, description }) =>
	async (dispatch, getState) => {
		console.log(name)
		try {
			dispatch({
				type: REDUX_CONST.assetConstants.createAsset.request,
			})

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${userInfo.token}`,
				},
			}
			const { data } = await axios.post(
				API_CONST.channels.assets,
				{ name, description },
				config,
			)
			// console.log(response.data)
			dispatch({
				type: REDUX_CONST.assetConstants.createAsset.success,
				payload: data,
			})
		} catch (error) {
			console.log(error)
			dispatch({
				type: REDUX_CONST.assetConstants.createAsset.fail,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}
