// autodesk-viewer.js
import React, { useEffect, useRef } from 'react'

const AutodeskViewer = ({ urn, token }) => {
	const viewerDiv = useRef(null) // Reference to the div where the viewer will be embedded

	// Callback function for when the geometry has been fully loaded
	const onGeometryLoaded = () => {
		const instanceTree = window.viewer.model.getData().instanceTree
		if (!instanceTree) {
			console.error('Instance tree not found.')
			return
		}
		let bomData = []
		instanceTree.enumNodeChildren(instanceTree.getRootId(), (childId) => {
			window.viewer.getProperties(
				childId,
				(props) => {
					const partData = {
						dbId: childId,
						name: props.name || '',
						type:
							props.properties.find((p) => p.displayName === 'Type')
								?.displayValue || '',
						material:
							props.properties.find((p) => p.displayName === 'Material')
								?.displayValue || '',
						quantity: 1, // Default quantity for parts
					}
					bomData.push(partData)
					console.log(`Part Data for dbId ${childId}:`, partData)
				},
				true,
			)
		})
		console.log('Complete BOM Data:', bomData)
	}

	useEffect(() => {
		async function initializeViewer() {
			if (!window.Autodesk) {
				console.error('Autodesk Viewer script not loaded')
				return
			}

			// Clean up any existing viewer instances before reinitializing
			if (window.viewer) {
				window.viewer.tearDown()
				window.viewer.finish()
				window.viewer = null
			}

			const options = {
				env: 'AutodeskProduction',
				accessToken: token,
			}

			window.Autodesk.Viewing.Initializer(options, () => {
				window.viewer = new window.Autodesk.Viewing.GuiViewer3D(
					viewerDiv.current,
				)
				window.viewer.start()

				const documentId = `urn:${urn}`
				window.Autodesk.Viewing.Document.load(
					documentId,
					(doc) => {
						const defaultModel = doc.getRoot().getDefaultGeometry()
						window.viewer.loadDocumentNode(doc, defaultModel)
						// Add event listener once the model's geometry is fully loaded
						window.viewer.addEventListener(
							window.Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
							onGeometryLoaded,
						)
					},
					(error) => {
						console.error('Error loading document:', error)
					},
				)
			})
		}

		if (urn && token) {
			initializeViewer()
		}

		// Cleanup on component unmount or when urn/token changes
		return () => {
			if (window.viewer) {
				window.viewer.removeEventListener(
					window.Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
					onGeometryLoaded,
				)
				window.viewer.tearDown()
				window.viewer.finish()
				window.viewer = null
			}
		}
	}, [urn, token])

	return (
		// Set the viewer container's dimensions using TailwindCSS utilities
		<div className='relative mt-4 mb-8' style={{ minHeight: '600px' }}>
			<div ref={viewerDiv} className='absolute inset-0 w-full h-full'></div>
		</div>
	)
}

export default AutodeskViewer
