export const CONST_LOCAL_STORAGE = {
	themeMode: 'theme-mode',
	userInfo: 'userInfo',
}

export const ROUTES_PAGES = {
	iot: 'iot',
	admin: '/admin',
	users: '/users',
	drawings: '/drawings',
	home: '/',
	login: '/login',
	designViews: '/design-views',
	projects: '/projects',
	project: '/project',
	assets: '/assets',
	asset: '/asset',
	add: '/add',
	viewAll: '/list',
	production: '/production',
}

// STANDARD COLORS
// 12 SHADES OF GRAY

export const COLOR_GRAY = {
	1: '#C30010',
	2: '#D1001F',
	3: '#DE0A26',
	4: '#F01E2C',
	5: '#FF2C2C',
	6: '#F94449',
	7: '#EE6B6E',
	8: '#F69697',
	9: '#FFCBD1',
}

//  SHADES OF BLUE
export const COLOR_GREEN = {
	1: '#D0F0C0',
	2: '#90EE90',
	3: '#98FF98',
	4: '#A7F432',
	5: '#7CFC00',
	6: '#32CD32',
	7: '#228C22',
	8: '#138808',
	9: '#005C29',
	10: '#004225',
	11: '#123524',
	12: '#013220',
}
//  SHADES OF YELLOW
export const COLOR_YELLOW = {
	1: '#FFFFE0',
	2: '#FAFAD2',
	3: '#FFFF9F',
	4: '#FFFF00',
	5: '#FFF44F',
	6: '#FFF017',
	7: '#FFF600',
	8: '#FFEF00',
	9: '#FFDF00',
	10: '#F5E050',
	11: '#EFCC00',
	12: '#EED202',
}

//  SHADES OF ORANGE

//  SHADES OF PURPLE

// OBJECT COLORS
export const DOT_COLORS = {
	inactiveDark: COLOR_GRAY[3],
	inactiveLight: COLOR_GRAY[6],
	activeDark: COLOR_GREEN[3],
	activeLight: COLOR_GREEN[7],
	activeYellow: 'rgb(254, 201, 15)',
	activeTeal: '#03C9D7',
}

export const COLOR_RED = {
	superRed: '#C30010',
	brightRed: '#D1001F',
	3: '#DE0A26',
	4: '#F01E2C',
	5: '#FF2C2C',
	6: '#F94449',
	7: '#EE6B6E',
	8: '#F69697',
	9: '#FFCBD1',
}

// Palette examples:
export const GREEN_PALETTE = {
	color1: '#03C9D7',
	color2: '#0b9ba6',
	color3: '#0e636a',
	color4: '#0c3e43',
	color5: '#d2d3d5',
	color6: '#191d28',
}

// Theme example:
// What should it look like?
// { lightColors:{}, darkColors:{grayColor,redColor,greenColor}}

export const STANDARD_COLOR_THEME = {
	grayShades: COLOR_GRAY,
	redShades: COLOR_RED,
	yellowShades: COLOR_YELLOW,
	greenShades: COLOR_GREEN,
	themePalette: GREEN_PALETTE,
	dotColors: DOT_COLORS,
}
