import React, { useEffect } from 'react'
import { PageWrapper, Links } from '../../components'

import { ROUTES_PAGES } from '../../constants'
import { useDispatch, useSelector } from 'react-redux'
import { listAssets } from '../../redux/actions/assetActions'
import { Link } from 'react-router-dom'

const links = [
	{ title: 'HOME', link: ROUTES_PAGES.assets },
	{ title: 'ADD NEW', link: `${ROUTES_PAGES.assets}${ROUTES_PAGES.add}` },
	{
		title: 'VIEW ALL',
		link: `${ROUTES_PAGES.assets}${ROUTES_PAGES.viewAll}`,
	},
]

const Assets = () => {
	const dispatch = useDispatch()

	const listAssetsRdx = useSelector((state) => state.listAssets)
	const { data, loading, error } = listAssetsRdx

	useEffect(() => {
		dispatch(listAssets())

		return () => {}
	}, [])

	useEffect(() => {
		console.log(listAssetsRdx)

		return () => {}
	}, [listAssetsRdx])
	return (
		<PageWrapper
			sidebarTitle='PROJECT LINKS'
			sidebarLinks={<Links links={links} />}
		>
			<div className='mt-4 mb-8'>
				<div className='font-montserrat font-semibold text-xl  text-center pb-4'>
					ASSETS SUMMARY
				</div>
				<div className='font-montserrat font-semibold text-xl  text-center pb-4'>
					{data ? (
						<div className='flex  space-x-4 justify-center items-center'>
							<div>{`${data.data.length} Assets -- yada yada`}</div>
							<Link
								className='rounded-theme-rounding py-1 px-4 border-1 border-gray-600'
								to={`${ROUTES_PAGES.assets}${ROUTES_PAGES.viewAll}`}
							>
								See all
							</Link>
						</div>
					) : error ? (
						error
					) : loading ? (
						'Loading asset summary...'
					) : (
						'No assets, one now!'
					)}
				</div>
			</div>
		</PageWrapper>
	)
}

export default Assets
