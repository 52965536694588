import { REDUX_CONST } from '../constants'

export const fetchAsset = (state = { data: {} }, action) => {
	switch (action.type) {
		case REDUX_CONST.assetConstants.fetchAsset.request:
			return { loading: true }
		case REDUX_CONST.assetConstants.fetchAsset.success:
			return { data: action.payload }
		case REDUX_CONST.assetConstants.fetchAsset.fail:
			return { error: action.payload }
		default:
			return state
	}
}

export const createAsset = (state = {}, action) => {
	switch (action.type) {
		case REDUX_CONST.assetConstants.createAsset.request:
			return { loading: true }
		case REDUX_CONST.assetConstants.createAsset.success:
			return { data: action.payload }
		case REDUX_CONST.assetConstants.createAsset.fail:
			return { error: action.payload }
		case REDUX_CONST.assetConstants.createAsset.reset:
			return {}
		default:
			return state
	}
}

// export const uploadAssetFile = (state = {}, action) => {
//     switch (action.type) {
//         case REDUX_CONST.assetConstants.uploadAssetFile.request:
//             return { loading: true }
//         case REDUX_CONST.assetConstants.uploadAssetFile.success:
//             return { data: action.payload }
//         case REDUX_CONST.assetConstants.uploadAssetFile.fail:
//             return { error: action.payload }
//         case REDUX_CONST.assetConstants.uploadAssetFile.reset:
//             return {}
//         default:
//             return state
//     }
// }

export const listAssets = (state = [], action) => {
	switch (action.type) {
		case REDUX_CONST.assetConstants.listAssets.request:
			return { loading: true }
		case REDUX_CONST.assetConstants.listAssets.success:
			return { data: action.payload }
		case REDUX_CONST.assetConstants.listAssets.fail:
			return { error: action.payload }
		case REDUX_CONST.assetConstants.listAssets.reset:
			return []
		default:
			return state
	}
}

// export const deleteUser = (state = {}, action) => {
// 	switch (action.type) {
// 		case REDUX_CONST.userConstants.deleteUser.request:
// 			return { loading: true }
// 		case REDUX_CONST.userConstants.deleteUser.success:
// 			return { data: action.payload }
// 		case REDUX_CONST.userConstants.deleteUser.fail:
// 			return { error: action.payload }
// 		case REDUX_CONST.userConstants.deleteUser.reset:
// 			return {}

// 		default:
// 			return state
// 	}
// }
// export const updateUser = (state = {}, action) => {
// 	switch (action.type) {
// 		case REDUX_CONST.userConstants.updateUser.request:
// 			return { loading: true }
// 		case REDUX_CONST.userConstants.updateUser.success:
// 			return { data: action.payload }
// 		case REDUX_CONST.userConstants.updateUser.fail:
// 			return { error: action.payload }
// 		case REDUX_CONST.userConstants.updateUser.reset:
// 			return {}
// 		default:
// 			return state
// 	}
// }
