export const assetConstants = {
	listAssets: {
		request: 'ASSETS_LIST_REQUEST',
		success: 'ASSETS_LIST_SUCCESS',
		fail: 'ASSETS_LIST_FAIL',
		reset: 'ASSETS_LIST_RESET',
	},
	fetchAsset: {
		request: 'ASSET_FETCH_REQUEST',
		success: 'ASSET_FETCH_SUCCESS',
		fail: 'ASSET_FETCH_FAIL',
	},
	// uploadAssetFile: {
	// 	request: 'UPLOAD_ASSET_FILE_REQUEST',
	// 	success: 'UPLOAD_ASSET_FILE_SUCCESS',
	// 	fail: 'UPLOAD_ASSET_FILE_FAIL',
	// 	reset: 'UPLOAD_ASSET_FILE_RESET',
	// },
	createAsset: {
		request: 'CREATE_ASSET_REQUEST',
		success: 'CREATE_ASSET_SUCCESS',
		fail: 'CREATE_ASSET_FAIL',
		reset: 'CREATE_ASSET_RESET',
	},
	// deleteHubDrawing: {
	// 	request: 'DELETE_DRAWING_FROM_HUB_REQUEST',
	// 	success: 'DELETE_DRAWING_FROM_HUB_SUCCESS',
	// 	fail: 'DELETE_DRAWING_FROM_HUB_FAIL',
	// 	reset: 'DELETE_DRAWING_FROM_HUB_RESET',
	// },
}
