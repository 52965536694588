import React, { useEffect, useState } from 'react'
import { PageWrapper, Links } from '../../components'

import { ROUTES_PAGES } from '../../constants'
// import UploadAssetFile from '../../components/assets/upload-asset-file'
// import { uploadAssetFile } from '../../redux/actions/assetActions'
import { createAsset, resetItems } from '../../redux/actions/assetActions'
import { useDispatch, useSelector } from 'react-redux'

const links = [
	{ title: 'HOME', link: ROUTES_PAGES.assets },
	{ title: 'ADD NEW', link: `${ROUTES_PAGES.assets}${ROUTES_PAGES.add}` },
	{
		title: 'VIEW ALL',
		link: `${ROUTES_PAGES.assets}${ROUTES_PAGES.viewAll}`,
	},
]

const AddAsset = () => {
	const dispatch = useDispatch()
	// const uploadAssetFileHandler = (file) => dispatch(uploadAssetFile(file))
	const { data, loading, error } = useSelector((state) => state.createAsset)

	const [name, setName] = useState('')
	const [description, setDescription] = useState('')
	const changeDescriptionHandler = (e) => setDescription(e.target.value)
	const changeNameHandler = (e) => setName(e.target.value)
	const submitHandler = (e) => {
		e.preventDefault()
		if (name !== '') {
			dispatch(createAsset({ name, description }))
		}
	}

	useEffect(() => {
		if (data) {
			console.log(data)
			dispatch(resetItems('create'))
		}

		return () => {}
	}, [data])

	return (
		<PageWrapper
			sidebarTitle='ASSET LINKS'
			sidebarLinks={<Links links={links} />}
		>
			<div className='max-w-6xl mx-auto'>
				<form onSubmit={submitHandler} className='max-w-96  mx-auto'>
					<input
						type='text'
						name='add_asset_no_autocomplete'
						value={name}
						onChange={changeNameHandler}
						placeholder='Enter Asset Name'
						// className=' pl-3 border-0 text-gray-600 text-sm  py-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-yellow-500'
						className='w-full px-6 py-4 focus:border-b-2 border-b-1 border-ssiLightBorder1 dark:border-ssiDarkBorder1 focus:border-ssiGold1 focus:dark:border-ssiGold1 bg-ssiLight1  focus:outline-none   dark:text-ssiDarkTextActive  dark:bg-ssiDark4 placeholder:font-sans placeholder:font-light'
					/>
					<input
						type='text'
						name='add_asset_description_no_autocomplete'
						value={description}
						onChange={changeDescriptionHandler}
						placeholder='Enter Description'
						// className=' pl-3 border-0 text-gray-600 text-sm  py-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-yellow-500'
						className='w-full px-6 py-4 focus:border-b-2 border-b-1 border-ssiLightBorder1 dark:border-ssiDarkBorder1 focus:border-ssiGold1 focus:dark:border-ssiGold1 bg-ssiLight1  focus:outline-none   dark:text-ssiDarkTextActive  dark:bg-ssiDark4 placeholder:font-sans placeholder:font-light'
					/>

					<button
						type='submit'
						className='my-6 text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2 5'
					>
						Create Asset
					</button>
					{/* <UploadAssetFile onUploadAssetFile={uploadAssetFileHandler} /> */}
				</form>
			</div>
		</PageWrapper>
	)
}

export default AddAsset
