import axios from 'axios'
import { API_CONST } from '../../constants/api/index.js'
import { REDUX_CONST } from '../constants/index.js'
export const resetItems = (key) => async (dispatch) => {
	switch (key) {
		case 'create':
			dispatch({ type: REDUX_CONST.projectConstants.createProject.reset })

			break

		default:
			break
	}
}
export const listProjects = () => async (dispatch, getState) => {
	// console.log('file')
	try {
		dispatch({
			type: REDUX_CONST.projectConstants.listProjects.request,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(API_CONST.channels.projects, config)

		// console.log(response.data)
		dispatch({
			type: REDUX_CONST.projectConstants.listProjects.success,
			payload: data,
		})
	} catch (error) {
		console.log(error)
		dispatch({
			type: REDUX_CONST.projectConstants.listProjects.fail,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}
export const fetchProject =
	({ id }) =>
	async (dispatch, getState) => {
		// console.log('file')
		try {
			dispatch({
				type: REDUX_CONST.projectConstants.fetchProject.request,
			})

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
			}
			const { data } = await axios.get(
				`${API_CONST.channels.projects}/${id}`,
				config,
			)

			dispatch({
				type: REDUX_CONST.projectConstants.fetchProject.success,
				payload: data,
			})
		} catch (error) {
			console.log(error)
			dispatch({
				type: REDUX_CONST.projectConstants.fetchProject.fail,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}
export const createProject =
	({ name, description }) =>
	async (dispatch, getState) => {
		console.log(name)
		try {
			dispatch({
				type: REDUX_CONST.projectConstants.createProject.request,
			})

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${userInfo.token}`,
				},
			}
			const { data } = await axios.post(
				API_CONST.channels.projects,
				{ name, description },
				config,
			)
			// console.log(response.data)
			dispatch({
				type: REDUX_CONST.projectConstants.createProject.success,
				payload: data,
			})
		} catch (error) {
			console.log(error)
			dispatch({
				type: REDUX_CONST.projectConstants.createProject.fail,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}
export const uploadProjectFile = (file) => async (dispatch, getState) => {
	// console.log('file')
	try {
		dispatch({
			type: REDUX_CONST.projectConstants.uploadProjectFile.request,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const formData = new FormData()
		formData.append('file', file)

		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const response = await axios.post(
			API_CONST.channels.projects + API_CONST.routes.uploadProjectFile,
			formData,
			config,
		)
		// console.log(response.data)
		dispatch({
			type: REDUX_CONST.projectConstants.uploadProjectFile.success,
			payload: response.data,
		})
	} catch (error) {
		console.log(error)
		dispatch({
			type: REDUX_CONST.projectConstants.uploadProjectFile.fail,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}
