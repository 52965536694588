import React from 'react'
import { PageWrapper, Links } from '../../components'

import { ROUTES_PAGES } from '../../constants'

const Production = () => {
	return (
		<PageWrapper
			sidebarTitle='PRODUCTION LINKS'
			sidebarLinks={
				<Links links={[{ title: 'HOME', link: ROUTES_PAGES.production }]} />
			}
		>
			<div className='mt-4 mb-8'>
				<div className='font-montserrat font-semibold text-xl  text-center pb-4'>
					PRODUCTION SUMMARY
				</div>
			</div>
		</PageWrapper>
	)
}

export default Production
