import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { logout } from '../../../redux/actions/userActions'
import { ROUTES_PAGES } from '../../../constants'
import { useStateContext } from '../../../contexts/ContextProvider'
import { CONST_DARK, CONST_LIGHT } from '../../../constants/general'
import { ICONS_DASHBOARDS, ICONS_DEFAULT } from '../../../constants/icons'

const MobileMenu = ({ onLogout, userInfo }) => {
	const { currentMode, setMode } = useStateContext()
	const LINKS = [
		{
			title: 'ASSETS',
			link: ROUTES_PAGES.assets,
			icon: ICONS_DASHBOARDS.assetsDashboard,
			type: 'LINK',
			className:
				'flex items-center justify-center gap-2 hover:scale-110 hover:text-ssiGold1 dark:hover:text-ssiGold1 font-bold',
			iconClassname: 'text-lg text-ssiGold1 dark:text-ssiGold1',
		},

		{
			title: 'PROJECTS',
			link: ROUTES_PAGES.projects,
			icon: ICONS_DASHBOARDS.projectsDashboard,
			type: 'LINK',
			className:
				'flex items-center justify-center gap-2 hover:scale-110 hover:text-ssiGold1 dark:hover:text-ssiGold1 font-bold',
			iconClassname: 'text-lg text-ssiGold1 dark:text-ssiGold1',
		},
		{
			title: 'PRODUCTION',
			link: ROUTES_PAGES.production,
			icon: ICONS_DASHBOARDS.production,
			type: 'LINK',
			className:
				'flex items-center justify-center gap-2 hover:scale-110 hover:text-ssiGold1 dark:hover:text-ssiGold1 font-bold',
			iconClassname: 'text-lg text-ssiGold1 dark:text-ssiGold1',
		},
		{
			title: 'DESIGN VIEWS',
			link: ROUTES_PAGES.designViews,
			icon: ICONS_DASHBOARDS.designViews,
			type: 'LINK',
			className:
				'flex items-center justify-center gap-2 hover:scale-110 hover:text-ssiGold1 dark:hover:text-ssiGold1 font-bold',
			iconClassname: 'text-lg text-ssiGold1 dark:text-ssiGold1',
		},
		{
			title: 'LOGOUT',
			link: onLogout,
			icon: ICONS_DEFAULT.logout,
			type: 'BUTTON',
			className:
				'flex items-center justify-center gap-2 hover:scale-110 hover:text-ssiGold1 dark:hover:text-ssiGold1 font-bold',
			iconClassname: 'text-lg text-ssiGold1 dark:text-ssiGold1',
		},
	]
	const ADMIN_LINKS = [
		{
			title: 'Admin',
			link: ROUTES_PAGES.admin,
			icon: ICONS_DEFAULT.settings,
			type: 'LINK',
			className:
				'flex items-center justify-center gap-2 hover:scale-110 hover:text-ssiGold1 dark:hover:text-ssiGold1 font-bold',
			iconClassname: 'text-lg text-ssiGold1 dark:text-ssiGold1',
		},
	]

	const links =
		userInfo?.isAdmin === true ? [...LINKS, ...ADMIN_LINKS] : [...LINKS]

	return (
		<div
			id='menu'
			className='absolute top-0 bottom-0 left-0 hidden flex-col self-end w-full min-h-screen py-1 pt-40 pl-12 space-y-3 text-lg bg-ssiLight2 dark:bg-ssiDark2 font-bold uppercase z-60 '
		>
			{links.map((link, idx) =>
				link.type === 'LINK' ? (
					<Link
						key={`${link.title}-${idx}`}
						to={link.link}
						className={link.className}
					>
						<div className={link.iconClassname}>{link.icon}</div>
						<div>{link.title}</div>
					</Link>
				) : (
					<button
						key={`${link.title}-${idx}`}
						onClick={link.link}
						className={link.className}
					>
						<div className={link.iconClassname}>{link.icon}</div>
						<div>{link.title}</div>
					</button>
				),
			)}

			{/* <!-- Dark/Light Mode Button --> */}
			{currentMode && currentMode === CONST_DARK && (
				<button
					onClick={() => setMode(CONST_LIGHT)}
					id='theme-toggle-dark'
					className='text-gray-500 w-8 items-center justify-center mx-auto dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2 5'
				>
					{/* <!-- Light SVG Icon --> */}
					<svg
						id='theme-toggle-light-icon'
						className='w-5 h-5 '
						fill='currentColor'
						viewBox='0 0 20 20'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z'
							fillRule='evenodd'
							clipRule='evenodd'
						></path>
					</svg>
				</button>
			)}

			{currentMode && currentMode === CONST_LIGHT && (
				<button
					onClick={() => setMode(CONST_DARK)}
					id='theme-toggle-light'
					className='text-gray-500 w-8 items-center justify-center mx-auto dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2 5'
				>
					{/* <!-- Dark SVG Icon --> */}
					<svg
						id='theme-toggle-dark-icon'
						className='w-5 h-5 '
						fill='currentColor'
						viewBox='0 0 20 20'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path d='M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z'></path>
					</svg>
				</button>
			)}
		</div>
	)
}

const Menu = ({ onLogout, userInfo }) => {
	const { currentMode, setMode } = useStateContext()
	const LINKS = [
		{
			title: 'ASSETS',
			link: ROUTES_PAGES.assets,
			icon: ICONS_DASHBOARDS.assetsDashboard,
			type: 'LINK',
			className:
				'flex items-center gap-2 hover:scale-110 hover:text-ssiGold1 dark:hover:text-ssiGold1 font-bold',
			iconClassName: 'text-lg text-ssiGold1 dark:text-ssiGold1',
		},
		{
			title: 'PROJECTS',
			link: ROUTES_PAGES.projects,
			icon: ICONS_DASHBOARDS.projectsDashboard,
			type: 'LINK',
			className:
				'flex items-center gap-2 hover:scale-110 hover:text-ssiGold1 dark:hover:text-ssiGold1 font-bold',
			iconClassName: 'text-lg text-ssiGold1 dark:text-ssiGold1',
		},
		{
			title: 'PRODUCTION',
			link: ROUTES_PAGES.production,
			icon: ICONS_DASHBOARDS.production,
			type: 'LINK',
			className:
				'flex items-center gap-2 hover:scale-110 hover:text-ssiGold1 dark:hover:text-ssiGold1 font-bold',
			iconClassName: 'text-lg text-ssiGold1 dark:text-ssiGold1',
		},
		{
			title: 'DESIGN VIEWS',
			link: ROUTES_PAGES.designViews,
			icon: ICONS_DASHBOARDS.designViews,
			type: 'LINK',
			className:
				'flex items-center gap-2 hover:scale-110 hover:text-ssiGold1 dark:hover:text-ssiGold1 font-bold',
			iconClassName: 'text-lg text-ssiGold1 dark:text-ssiGold1',
		},
		{
			title: 'LOGOUT',
			link: onLogout,
			icon: ICONS_DEFAULT.logout,
			type: 'BUTTON',
			className:
				'flex items-center gap-2 hover:scale-110 hover:text-ssiGold1 dark:hover:text-ssiGold1 font-bold',
			iconClassName: 'text-lg text-ssiGold1 dark:text-ssiGold1',
		},
	]
	const ADMIN_LINKS = [
		{
			title: 'Admin',
			link: ROUTES_PAGES.admin,
			icon: ICONS_DEFAULT.settings,
			type: 'LINK',
			className:
				'flex items-center gap-2 hover:scale-110 bg-ssiGold1 dark:bg-ssiGold1 text-ssiDark2  dark:text-ssiDark4 rounded-lg px-4 py-2 font-semibold',
			iconClassName: 'text-lg text-ssiDark2 font-semibold  dark:text-ssiDark4',
		},
	]

	const links =
		userInfo?.isAdmin === true ? [...LINKS, ...ADMIN_LINKS] : [...LINKS]

	return (
		<div className='hidden h-10 font-alata md:flex md:space-x-8 items-center justify-center text-sm'>
			{links.map((link, idx) =>
				link.type === 'LINK' ? (
					<Link
						key={`${link.title}-${idx}`}
						to={link.link}
						className={link.className}
					>
						<div className={link.iconClassName}>{link.icon}</div>
						<div>{link.title}</div>
					</Link>
				) : (
					<button
						key={`${link.title}-${idx}`}
						onClick={link.link}
						className={link.className}
					>
						<div className={link.iconClassName}>{link.icon}</div>
						<div>{link.title}</div>
					</button>
				),
			)}

			{/* <!-- Dark/Light Mode Button --> */}
			{currentMode && currentMode === CONST_DARK && (
				<button
					onClick={() => setMode(CONST_LIGHT)}
					id='theme-toggle-dark'
					className='text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2 5'
				>
					{/* <!-- Light SVG Icon --> */}
					<svg
						id='theme-toggle-light-icon'
						className='w-5 h-5 '
						fill='currentColor'
						viewBox='0 0 20 20'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z'
							fillRule='evenodd'
							clipRule='evenodd'
						></path>
					</svg>
				</button>
			)}

			{currentMode && currentMode === CONST_LIGHT && (
				<button
					onClick={() => setMode(CONST_DARK)}
					id='theme-toggle-light'
					className='text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2 5'
				>
					{/* <!-- Dark SVG Icon --> */}
					<svg
						id='theme-toggle-dark-icon'
						className='w-5 h-5 '
						fill='currentColor'
						viewBox='0 0 20 20'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path d='M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z'></path>
					</svg>
				</button>
			)}
		</div>
	)
}

const HomePageLinks = () => {
	const dispatch = useDispatch()

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	function navToggle() {
		const btn = document.getElementById('menu-btn')
		const menu = document.getElementById('menu')
		btn.classList.toggle('open')
		menu.classList.toggle('flex')
		menu.classList.toggle('hidden')
	}

	const logoutHandler = () => {
		dispatch(logout())
	}
	return (
		<>
			{/* // Menu */}

			<Menu onLogout={logoutHandler} userInfo={userInfo} />

			{/* <!-- Hamburger Button --> */}
			<div className='md:hidden'>
				<button
					onClick={navToggle}
					id='menu-btn'
					type='button'
					className='z-70 block hamburger md:hidden focus:outline-none'
				>
					<span className='hamburger-top bg-slate-600 dark:bg-white'></span>
					<span className='hamburger-middle bg-slate-600 dark:bg-white'></span>
					<span className='hamburger-bottom bg-slate-600 dark:bg-white'></span>
				</button>
			</div>
			{/* <!-- Mobile Menu --> */}
			<MobileMenu onLogout={logoutHandler} userInfo={userInfo} />
		</>
	)
}

export default HomePageLinks
