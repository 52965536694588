//kpi-chart-line.js
import React from 'react'
import {
	CartesianGrid,
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts'
import { CONST_COLORS } from '../../../constants/general'

const TitledLineChart = ({
	data,
	title,
	icon,
	keyDataLine1,
	keyDataLine2,
	keyXLabel,
	lineType,
	height,
	fill1,
	stroke1,
	strokeDasharray1,
	strokeWidth1,
	fill2,
	stroke2,
	strokeDasharray2,
	strokeWidth2,
}) => {
	const maxValue = Math.max(...data.map((item) => item[keyDataLine1])) + 2
	return (
		<div className='grid grid-cols-1 md:grid-cols-5'>
			<div className='col-span-1 md:col-span-1 text-center text-ssiGray800Scorecard dark:text-ssiGray200Scorecard  rounded-lg mt-8 z-10'>
				<div className='flex flex-col text-center  justify-center items-center  space-y-6 p-5'>
					<div className=' text-xl font-bold'>{title}</div>

					<div className='text-6xl text-ssiGoldScorecard'>{icon}</div>
				</div>
			</div>
			<div className='col-span-1 md:col-span-4 text-black text-center  rounded-b-lg md:rounded-r-lg -mt-2 md:-ml-2  md:mt-8 z-8 pt-6 md:pt-0 pl-4'>
				{/* Recharts Line Chart */}
				<ResponsiveContainer width='100%' height={height || 200}>
					<LineChart
						data={data}
						margin={{ top: 20, right: 20, left: -20, bottom: 5 }}
					>
						<CartesianGrid strokeDasharray='3 3' />
						<XAxis dataKey={keyXLabel} tick={{ fontSize: 12 }} />
						<YAxis tick={{ fontSize: 12 }} domain={[0, maxValue]} />
						<Tooltip />
						<Line
							type={lineType || 'monotone'}
							dataKey={keyDataLine1}
							fill={fill1 || CONST_COLORS.ssiGoldScorecard}
							stroke={stroke1 || CONST_COLORS.ssiGoldScorecard}
							strokeDasharray={strokeDasharray1 || undefined}
							strokeWidth={strokeWidth1 || 1}
							activeDot={{ r: 5 }}
						/>
						{keyDataLine2 && (
							<Line
								type={lineType || 'monotone'}
								dataKey={keyDataLine2}
								strokeDasharray={strokeDasharray2}
								strokeWidth={strokeWidth2}
								fill={fill2 || CONST_COLORS.ssiGoldScorecard}
								stroke={stroke2 || CONST_COLORS.ssiGoldScorecard}
								activeDot={{ r: 5 }}
							/>
						)}
					</LineChart>
				</ResponsiveContainer>
			</div>
		</div>
	)
}

export default TitledLineChart
