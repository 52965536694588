//light-control.js
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GEN_CONST } from '../../../constants/general'
import { useDispatch } from 'react-redux'
import {
	controlOutput,
	getDevice,
} from '../../../redux/actions/IoTActions/outputActions'
import { io } from 'socket.io-client'
import TitledLineChart from '../../../components/UIElements/charts/kpi-chart-line'
import { ICONS_DEFAULT } from '../../../constants/icons'

const DEVICE_NAME = 'ESP32-1'

const PIN_MAP = { DO_26_LED: 'Green Light' }

const LightControl = () => {
	const dispatch = useDispatch()
	const { data, loading, error } = useSelector((state) => state.controlOutput)
	const getDeviceRdx = useSelector((state) => state.getDevice)

	const [deviceDo, setDeviceDo] = useState([])
	const [deviceAi, setDeviceAi] = useState([])
	const [lineCharts, setLineCharts] = useState([])

	const handleControlLight = (action, device) => {
		console.log('Controlling Light: Turning ', action)
		dispatch(controlOutput(action, device, 'DO_26_LED'))
	}

	useEffect(() => {
		dispatch(getDevice({ device: DEVICE_NAME }))
		return () => {}
	}, [])

	useEffect(() => {
		if (data?.device?.digitalOut?.length) {
			setDeviceDo(data.device.digitalOut)
		}
		if (data?.device?.analogIn?.length) {
			setDeviceAi(data.device.analogIn)
		}

		return () => {}
	}, [data])

	useEffect(() => {
		// console.log(getDeviceRdx)
		if (getDeviceRdx?.data?.device.digitalOut) {
			setDeviceDo(getDeviceRdx.data.device.digitalOut)
		} else {
			setDeviceDo([])
		}

		if (getDeviceRdx?.data?.device.analogIn) {
			setDeviceAi(getDeviceRdx.data.device.analogIn)
		} else {
			setDeviceAi([])
		}

		return () => {}
	}, [getDeviceRdx])

	useEffect(() => {
		// io('http://localhost:5000')
		// Connect to the Socket.IO server
		// Replace 'http://localhost:5000' with your server's URL
		//const socket = io('http://localhost:5000', { transports: ['websocket'] })
		const socket = io('https://www.mymarine.tech', {
			transports: ['websocket'],
		})
		// Setup event listeners

		socket.on('DEVICE_UPDATE', (data) => {
			if (data?.action === DEVICE_NAME) {
				console.log(data.payload)

				if (data?.payload?.digitalOut?.length) {
					console.log(data.payload.digitalOut)
					setDeviceDo(data.payload.digitalOut)
				}
				if (data?.payload?.analogIn?.length) {
					console.log(data.payload.analogIn)
					setDeviceAi(data.payload.analogIn)
				}
			}
		})

		// Clean up the effect
		return () => socket.disconnect()
	}, [])

	useEffect(() => {
		if (deviceAi?.length) {
			console.log(deviceAi)
			const mappedData = deviceAi.map((obj) => {
				const objDate = new Date(obj.timestamp)

				return {
					...obj,
					date: objDate,
					time: `${String(objDate.getHours()).padStart(2, '0')}:${String(
						objDate.getMinutes(),
					).padStart(2, '0')}:${String(objDate.getSeconds()).padStart(2, '0')}`,
				}
			})

			setLineCharts([
				{
					title: deviceAi[0].key, // This should be the key of the digitalInput[index]
					icon: ICONS_DEFAULT.addCircle,
					keyData: 'value',
					keyXLabel: 'time',
					data: mappedData,
				},
			])
		}

		return () => {}
	}, [deviceAi])

	return (
		<div className=' p-4 flex flex-col'>
			<h1 className='text-2xl font-bold text-ssiDark4 dark:text-ssiLight4 mb-6'>
				DEVICE ESP32-1
			</h1>

			{deviceDo?.length > 0 &&
				deviceDo.map((pin) => (
					<div key={pin.key}>
						<div className='flex space-x-2 mb-2'>
							<div>{`Pin 26 Status (${PIN_MAP[pin.key]}): `}</div>
							<div
								className={
									pin.value === 'LOW' ? 'text-red-600' : 'text-green-600'
								}
							>
								{pin.value === 'LOW' ? '  Off' : '  On'}
							</div>
						</div>
						<div className='flex flex-col mx-8 md:mx-0 md:flex-row space-y-4 spacex-0 md:space-x-4 md:space-y-0'>
							{/* {pin.value === 'LOW' ? ( */}
							<button
								onClick={() =>
									handleControlLight(
										pin.value === 'LOW' ? GEN_CONST.HIGH : GEN_CONST.LOW,
										DEVICE_NAME,
									)
								}
								disabled={loading}
								className={`px-4 py-2 rounded text-white ${
									loading
										? 'bg-gray-400 cursor-not-allowed'
										: pin.value === 'LOW'
										? 'bg-green-500 hover:bg-green-600'
										: 'bg-red-500 hover:bg-red-600'
								}`}
							>
								{pin.value === 'LOW' ? 'TURN ON' : 'TURN OFF'}
							</button>
						</div>
					</div>
				))}
			{/* {loading && (
				<p className='text-blue-600 mt-4 animate-pulse'>Loading...</p>
			)} */}
			{error && <p className='text-red-600 mt-4'>Error: {error}</p>}
			{getDeviceRdx?.error && (
				<p className='text-red-600 mt-4'>
					Error: {JSON.stringify(getDeviceRdx.error)}
				</p>
			)}
			{lineCharts.map((e, idx) => (
				<TitledLineChart
					title={e.title}
					keyXLabel={e.keyXLabel}
					key={idx}
					icon={e.icon}
					keyDataLine1={e.keyData}
					data={e.data}
				/>
			))}
		</div>
	)
}

export default LightControl
